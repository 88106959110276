import * as Sentry from '@sentry/nextjs'

export type CookieValue =
  | string
  | number
  | boolean
  | { [key: string]: string | number | boolean }

export function saveCookie<T extends CookieValue>(
  key: string,
  value: T,
  days: number = 30,
): boolean {
  try {
    if (typeof document !== 'undefined') {
      const expires = new Date()
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
      const cookieValue = encodeURIComponent(JSON.stringify(value))
      document.cookie = `${key}=${cookieValue};expires=${expires.toUTCString()};path=/`

      return true
    } else {
      return false
    }
  } catch (error) {
    Sentry.captureException(error)

    return false
  }
}

export function fetchCookie<T extends CookieValue>(key: string): T | null {
  try {
    if (typeof document !== 'undefined') {
      const cookies = document.cookie.split(';')
      for (const cookie of cookies) {
        const [name, value] = cookie.trim().split('=')
        if (name === key) {
          return value as T
        }
      }

      return null
    } else {
      return null
    }
  } catch (error) {
    Sentry.captureException(error)

    return null
  }
}

export function removeCookie(key: string): boolean {
  try {
    if (typeof document !== 'undefined') {
      document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`

      return true
    } else {
      return false
    }
  } catch (error) {
    Sentry.captureException(error)

    return false
  }
}
