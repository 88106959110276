import { fetchCookie } from '@/utils/cookie-storage'
import { LEAD_ID_KEY } from '../hightouch/events'

export interface KetchConsent {
  analytics: boolean
  essential_services: boolean
  personalization: boolean
  product_enhancement: boolean
  [key: string]: boolean
}

export interface Metadata {
  lead_id?: string
}

export const getKetchConsent = (): Promise<KetchConsent> => {
  return new Promise((resolve) => {
    if (typeof window !== 'undefined' && window.ketch) {
      window.ketch('getConsent', (consentData: { purposes: KetchConsent }) => {
        resolve(consentData.purposes)
      })
    } else {
      resolve({
        analytics: false,
        product_enhancement: false,
        personalization: false,
        essential_services: true,
      })
    }
  })
}

export const canTrack = (consentState: KetchConsent): boolean =>
  consentState.analytics

export const canUseLocalStorage = (consentState: KetchConsent): boolean =>
  consentState.essential_services

export const getLeadId = (): string | null => {
  return fetchCookie<string>(LEAD_ID_KEY) || null
}

export const getKetchMetadata = async (): Promise<Record<string, any>> => {
  const consent = await getKetchConsent()
  const leadId = getLeadId()

  if (canTrack(consent) && leadId) {
    return {
      consent: consent,
      lead_id: leadId,
    }
  }

  return {}
}
